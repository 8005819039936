<template>
    <div class="find_box">
        <div class="search_box flex-center">
            <div class="list">
                <el-cascader
                    size="large"
                    :options="options"
                    :props="props"
                    v-model="AreaArray"
                    @change="selectArea"
                    placeholder="请选择省市"
                    clearable
                ></el-cascader>
            </div>
            
            <div class="list" v-if="isShow == true">
                <el-select v-model="value" clearable placeholder="装饰服务人员">
                    <el-option
                    v-for="item in serverType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="list">
                <el-input v-model="params.storeName" clearable placeholder="请输入关键字"></el-input>
            </div>
             <div class="list">
                <div class="btn display pointer" @click="findBtn">查找</div>
            </div>
        </div>
    </div>
</template>
<script>
import areaJSON from "../../../http/area.json";
export default {
    props:{
        isShow:{}
    },
    data(){
        return{
            options:areaJSON,
            serverType:[
                {
                    value: 1,
                    label: "装饰服务",
                },
                {
                    value: 2,
                    label: "技能培训",
                },
            ],
            props: {
                children: "children",
                label: "name",
                value: "name",
            },
            AreaArray:[],
            value: '',
            input:"",
            params:{
                province:"",
                city:"",
                district:"",
                storeName:""
            }
        }
    },
    mounted(){},
    methods:{
        // 选择省市区
        selectArea(val) {
            this.params.province = val[0];
            this.params.city = val[1];
            this.params.district = val[2];
        },
        //查找
        findBtn(){
            this.$emit('findBtn',this.params)
        }
    }
    
}
</script>
<style lang="scss" scoped>
    .find_box{
        .el-select{
            width: 280px;
        }
        ::v-deep.el-select .el-input.is-focus .el-input__inner{
            border-color: #03825A;
        }
        ::v-deep.el-select .el-input__inner:focus{
            border-color: #03825A;
        }
        .list{
            margin-right: 10px;
            .btn{
                width: 168px;
                height: 40px;
                background: #03825A;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                border-radius: 4px;
            }
        }
    }
</style>
<style scoped>
    .el-select-dropdown__item.selected{
            color:  #03825A !important
        }
        .el-cascader{
            width: 280px;
        }
</style>