<template>
	<div class="join_box">
		<div class="content">
			<div class="title">
				<span>盟店</span>
				<span class="green">查询</span>
			</div>
			<div class="Find_box">
				<Find @findBtn="findBtn" :isShow="false"></Find>
			</div>
			<div class="map_box flex">
				<div class="map_fl">
					<div class="list_box sidebar">
						<div class="list flex" v-for="(item, index) in list" :key="index">
							<div class="serialNum">
								<img src="@/assets/img/serviceSystem/positioning.png" alt="" />
								<span class="num display">{{ index + 1 }}</span>
							</div>
							<div class="details">
								<div class="name row">
									{{
										item.province + item.city + item.district + item.storeName
									}}
								</div>
								<div class="address">
									{{
										item.province +
										item.city +
										item.district +
										item.detailAddress
									}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="map_fr">
					<img src="@/assets/img/serviceSystem/map.png" alt="" />
				</div>
			</div>
		</div>
		<div class="city_box">
			<div class="main">
				<div class="title">
					<span>服务</span>
					<span class="green">城市</span>
				</div>
				<div class="city_list">
					<div
						class="list flex-center"
						v-for="(item, index) in cityList"
						:key="index"
					>
						<div class="province">【{{ item.province }}】</div>
						<div
							class="city"
							v-for="(items, indexs) in item.citys"
							:key="indexs"
						>
							{{ (indexs == item.citys.length - 1 && items) || items + '、' }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Find from './components/find.vue'
export default {
	components: {
		Find,
	},
	data() {
		return {
			list: [],
			cityList: [],
			params: {
				province: '',
				city: '',
				district: '',
				storeName: '',
			},
		}
	},
	mounted() {
		this.init()
		this.getProvince()
	},
	methods: {
		init() {
			this.api.ConditionalQuery(this.params).then((res) => {
				if (res.code == 0) {
					this.list = res.data || []
				}
			})
		},
		getProvince() {
			this.api.selectByProvince().then((res) => {
				if (res.code == 0) {
					this.cityList = res.data.data || []
				}
			})
		},
		//查找
		findBtn(data) {
			this.params = data
			this.init()
		},
	},
}
</script>
<style lang="scss" scoped>
.join_box {
	.title {
		font-size: 36px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 55px;
		.green {
			color: #03825a;
		}
	}
	.content {
		width: 1200px;
		margin: 0 auto;
		padding-top: 59px;

		.Find_box {
			margin-top: 39px;
		}
		.map_box {
			height: 580px;
			margin-top: 30px;
			.map_fl {
				width: 396px;
				height: 580px;
				background: white;

				padding: 38px 40px 38px 18px;
				box-sizing: border-box;
				.list_box {
					height: 510px;
					overflow: hidden;
					overflow-y: scroll;
				}
				.list {
					margin-bottom: 30px;
					.serialNum {
						width: 22px;
						height: 29px;
						margin-right: 12px;
						position: relative;
						img {
							width: 22px;
							height: 29px;
						}
						.num {
							width: 22px;
							height: 29px;
							position: absolute;
							top: -2px;
							left: 0;
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffffff;
						}
					}
					.details {
						width: 307px;
						font-size: 14px;
						.name {
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #333333;
							line-height: 14px;
						}
						.address {
							color: #888888;
						}
					}
				}
			}
			.map_fr {
				img {
					width: 805px;
					height: 580px;
				}
			}
		}
	}
	.city_box {
		width: 100%;
		margin-top: 80px;
		padding-top: 83px;
		padding-bottom: 112px;
		background: white;
		.main {
			width: 1200px;
			margin: 0 auto;
			.city_list {
				margin-top: 36px;
			}
			.list:nth-child(2n) {
				background: #fff;
			}
			.list {
				width: 1201px;
				height: 50px;
				font-size: 16px;
				background: #f4f4f4;
				.province {
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #03825a;
				}
				.city {
					color: #333333;
				}
			}
		}
	}
}
</style>